import React, { useEffect } from 'react'
import { MainLayout } from 'components/layouts'
import css from './style.module.scss'
import { MakeOrder } from 'components/orders'
import { fetchCities } from 'store/modules/cities/actions'
import { connect, useDispatch } from 'react-redux'
import SEO from 'components/seo'

export default connect(
  state => ({
    hasOrder: state.orders.has_order,
  })
)(MakeOrderPage)

const options = [
  { value: 'moscow', label: 'Москва' },
  { value: 'petersburg', label: 'Санкт-Петербург' },
  { value: 'london', label: 'Лондон' },
];

function MakeOrderPage({
  hasOrder,
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCities())
  }, [])

  return (
    <MainLayout>
      <SEO
        title='Оформить заявку'
      />
      <div className='container'>
        <div className={css.makeOrderPage}>
          <div className={css.makeOrderWrapper}>
            {
              hasOrder &&
                <MakeOrder options={options} />
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
